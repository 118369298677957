import { loginFailure, loginStart, loginSuccess, loginEnd } from "./userRedux";
import { publicRequest, userRequest } from "../requestMethods";
import { persistor } from "./store";
import Swal from "sweetalert2";

export const login = async (dispatch, user) => {
  dispatch(loginStart());
  try {
    const res = await publicRequest.post(`standard/loginMoodaPainel`, user);
    dispatch(loginSuccess(res.data));
    persistor.flush();
  } catch (err) {
    Swal.fire("Atenção!", "Usuário ou senha inválido!", "warning");
    dispatch(loginFailure());
  }
};

export const logOut = async (dispatch) => {
  try {
    dispatch(loginEnd());
  } catch (err) {}
};
