import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import clients from "../icons/clientsBlack.svg";
import dash from "../icons/dashboardBlack.svg";
import home from "../icons/homeBlack.svg";
import importacao from "../icons/importacaoBlack.svg";
import orderIcon from "../icons/orderIconBlack.svg";
import products from "../icons/productsBlack.svg";
import config from "../icons/settingsBlack.svg";
import { generateFileUrl } from "../requestMethods";
import { useCompany } from "./context/CompanyContext";
import AccountMenu from "./menu/AccountMenu";
import "./topbar.css";
import Toolbar from "@mui/material/Toolbar";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";

const drawerWidth = 200;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  backgroundColor: "white",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function Topbar(props) {
  const { imgLogo } = useCompany();
  const [label, setLabel] = useState();
  const [logo, setLogo] = useState();
  const [open, setOpen] = useState(true);
  const currentTitle = useSelector((state) => state.titleRef.currentTitle);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <AppBar open={open}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <table width="100%" className="tableBorder">
            <tr>
              <td width="20%" className="center">
                <div className="divtitle">
                  <img src={logo} />
                  <span className="title">Painel Mooda</span>
                </div>
              </td>
              <td width="60%" className="center">
                {/* <img className="logoimg" src="\public" /> */}
              </td>
              <td width="20%" className="right">
                <AccountMenu />
              </td>
            </tr>
          </table>
        </Toolbar>
      </AppBar>
    </>
  );
}
