import "./report.css";
import { useEffect, useState } from "react";
import { userRequest } from "../../requestMethods";
import React from "react";
import { retunrMonth } from "../../util/helper";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import catalogo from "../../icons/catalogo.svg";
import cliente from "../../icons/cliente.svg";

export default function Home() {
  const [companyMonth, setCompanyMonth] = useState();
  const [companyMonthPayment, setCompanyMonthPayment] = useState();
  const [ativos, setAtivos] = useState();
  const [inativos, setInativos] = useState();
  const [total, setTotal] = useState();

  useEffect(() => {
    getCompanyMonth();
    getCompanyMonthPayment();
    getCompanies();
    window.scrollTo(0, 0);
  }, []);

  const getCompanyMonth = async () => {
    try {
      const res = await userRequest.get(`company/month`);
      setCompanyMonth(
        res.data.map((item) => ({
          criado: retunrMonth(item.createdAt),
          count: item.count,
        }))
      );
    } catch {}
  };
  const getCompanyMonthPayment = async () => {
    try {
      const res = await userRequest.get(`company/monthpayment`);
      setCompanyMonthPayment(
        res.data.map((item) => ({
          criado: retunrMonth(item.createdAt),
          count: item.count,
        }))
      );
    } catch {}
  };
  const getCompanies = async () => {
    try {
      const res = await userRequest.get(`company`);

      let companies = [];
      companies = res.data;

      setAtivos(companies.filter((item) => item.enable === true).length);
      setInativos(companies.filter((item) => item.enable === false).length);
      setTotal(companies.length);
    } catch (error) {}
  };
  return (
    <div className="div_home_Container">
      <table width="100%" className="tableSpaceTab">
        <tr>
          <div className="div_home_custom">
            <table className="table">
              <tr>
                <td align="left" width="100%">
                  <tr>
                    <td>
                      <img src={cliente} />
                    </td>
                    <td>
                      <tr className="labelTitle">Status do cliente</tr>
                      <tr className="labelMsgData">
                        Exibindo dados da data atual
                      </tr>
                    </td>
                  </tr>
                </td>
                <td align="left" width="0%">
                  {" "}
                </td>
              </tr>
            </table>

            <table className="table_painel">
              <tr>
                <td width="34%" className="status_painel">
                  Total
                </td>
                <td width="33%" className="status_painel">
                  Ativos
                  {/* <span className="labelMsgDataAtivos">(Últimos 90 dias)</span> */}
                </td>
                <td width="33%" className="status_painel">
                  Inativos
                  {/* <span className="labelMsgDataAtivos">
                    (Compras de 91 a 180 dias)
                  </span> */}
                </td>
              </tr>
              <tr>
                <td
                  className="labelValue_painel_black"
                  // onClick={getAnaliticoStatusQtdeCustomer}
                >
                  {total ? total : 0}
                </td>
                <td
                  className="labelValue_painel_black"
                  // onClick={getAnaliticoStatusAtivos}
                >
                  {ativos ? ativos : 0}
                </td>
                <td
                  className="labelValue_painel_black"
                  // onClick={getAnaliticoStatusInativos}
                >
                  {inativos ? inativos : 0}
                </td>
              </tr>
            </table>
          </div>
        </tr>
      </table>
      <table width="100%" className="tableSpaceTab">
        <tr>
          <td>
            <div className="div_home_custom">
              <table className="table">
                <tr>
                  <td align="left" width="100%">
                    <tr>
                      <td>
                        <img src={catalogo} />
                      </td>
                      <td>
                        <tr className="labelTitle">Implantação clientes</tr>
                        <tr className="labelMsgData">
                          Exibindo dados do primeiro dia do ano até hoje
                        </tr>
                      </td>
                    </tr>
                  </td>
                  <td align="left" width="0%">
                    {" "}
                  </td>
                </tr>
              </table>
              <br></br>
              <ResponsiveContainer width="95%" height={250}>
                <BarChart data={companyMonth}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    interval={0}
                    angle={-40}
                    type="category"
                    dataKey="criado"
                    tick={{ fontSize: 12 }}
                  />
                  <YAxis type="number" tick={{ fontSize: 12 }} />
                  <Tooltip />
                  <Bar dataKey="count" fill="#EE8253" barSize={5} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </td>
          <td>
            <div className="div_home_custom">
              <table className="table">
                <tr>
                  <td align="left" width="100%">
                    <tr>
                      <td>
                        <img src={catalogo} />
                      </td>
                      <td>
                        <tr className="labelTitle">Cliente com pagamento</tr>
                        <tr className="labelMsgData">
                          Exibindo dados do primeiro dia do ano até hoje
                        </tr>
                      </td>
                    </tr>
                  </td>
                  <td align="left" width="0%">
                    {" "}
                  </td>
                </tr>
              </table>
              <br></br>
              <ResponsiveContainer width="95%" height={250}>
                <BarChart data={companyMonthPayment}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    interval={0}
                    angle={-40}
                    type="category"
                    dataKey="criado"
                    tick={{ fontSize: 12 }}
                  />
                  <YAxis type="number" tick={{ fontSize: 12 }} />
                  <Tooltip />
                  <Bar dataKey="count" fill="#EE8253" barSize={5} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </td>
        </tr>
      </table>
    </div>
  );
}
