import "../../global.css";
import "./onboarding.css";
import { useEffect, useState } from "react";
import { publicRequest, urlAdminMooda, urlMooda } from "../../requestMethods";
import { formatTimeYYYYMMDD } from "../../util/helper";
import Swal from "sweetalert2";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import Container from "@mui/material/Container";

const Company = () => {
  const [inputs, setInputs] = useState({});
  const [plano, setPlano] = useState("");
  const [criado, setCriado] = useState(false);
  const [showDashboard, setShowDashboard] = useState();
  const [showFrete, setShowFrete] = useState();
  const [razao_social, setRzao_social] = useState();
  const [socio, setSocio] = useState();
  const [end_empresa, setEnd_empresa] = useState();
  const [end_num_empresa, setEnd_num_empresa] = useState();
  const [end_comp_empresa, setEnd_comp_empresa] = useState();
  const [end_bairro, setEnd_bairro] = useState();
  const [end_cep, setEnd_cep] = useState();
  const [end_ddd, setEnd_ddd] = useState();
  const [end_tel, setEnd_tel] = useState();
  const [end_estado, setEnd_estado] = useState();
  const [end_cidade, setEnd_cidade] = useState();
  const [cnpj_cpf, setCnpj_cpf] = useState();
  const [cep, setCep] = useState("");
  const [street, setStreet] = useState("");
  const [complement, setComplement] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [states, setStates] = useState([]);
  const [dataNiver, setDateNiver] = useState();
  const [tipoTelPay, setTipoTelPay] = useState();
  const [tipoTelEmp, setTipoTelEmp] = useState();

  useEffect(() => {
    getStates();
  }, []);

  const handleChangePlano = (e) => {
    setPlano(e.target.value);
  };
  const handleChangeTelPay = (e) => {
    setTipoTelPay(e.target.value);
  };
  const handleChangeTelEmp = (e) => {
    setTipoTelEmp(e.target.value);
  };
  const handleChangeDash = (e) => {
    setShowDashboard(e.target.value);
  };
  const handleChangeFrete = (e) => {
    setShowFrete(e.target.value);
  };
  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleChangeCEP = (e) => {
    if (e.target.value.length === 8) {
      getCep(e.target.value);
    }
  };

  const validaSlug = async (slug) => {
    const res = await publicRequest.get(`/company/${slug}`);

    if (Object.keys(res.data).length > 0) {
      return false;
    }
    return true;
  };
  const getCNPJ = async (cnpj) => {
    const res = await publicRequest.get(`1/customers/cnpj/${cnpj}`);

    if (Object.keys(res.data).length > 0) {
      setRzao_social(res.data.razao_social);
      setSocio(res.data.socio);
      setEnd_empresa(res.data.end_empresa);
      setEnd_num_empresa(res.data.end_num_empresa);
      setEnd_comp_empresa(res.data.end_comp_empresa);
      setEnd_bairro(res.data.end_bairro);
      setEnd_cep(res.data.end_cep);
      setEnd_ddd(res.data.end_ddd);
      setEnd_tel(res.data.end_tel);
      setEnd_estado(res.data.end_estado);
      setEnd_cidade(res.data.end_cidade);
    }
    setCnpj_cpf(cnpj);
  };
  const getCep = async (cep) => {
    const res = await publicRequest.get(`1/customers/cep/${cep}`);

    setCep(cep);

    if (Object.keys(res.data).length > 0) {
      setStreet(res.data.logradouro.toUpperCase());
      setDistrict(res.data.bairro.toUpperCase());
      setCity(res.data.localidade.toUpperCase());
      setState(res.data.uf.toUpperCase());
    }
  };
  const handleChangeCNPJ = (e) => {
    if (e.target.value.length === 14) {
      getCNPJ(e.target.value.trim());
    }
  };
  const click = async () => {
    let obj = {
      name: razao_social,
      cnpj: cnpj_cpf,
      contact: inputs.contactDDDPay + inputs.contactPay,
      email: inputs.emailPay,
      address: {
        street: end_empresa,
        number: end_num_empresa,
        complement: end_comp_empresa,
        locality: end_bairro,
        city: end_cidade,
        region_code: end_estado,
        country: "BRA",
        postal_code: end_cep,
      },
      CEP: cep,
      city: city,
      state: state,
      rzSocial: razao_social,
      slug: inputs.slug,
      planContract: {
        plan: plano,
        showDashboard: showDashboard,
        delivery: showFrete,
        showPrice: false,
      },
      configRules: { minProducts: 10 },
      enable: true,
      accountPay: {
        type: "SELLER",
        person: {
          address: {
            street: street,
            number: inputs.numberPay,
            complement: inputs.complementPay,
            locality: district,
            city: city,
            region_code: state,
            country: "BRA",
            postal_code: cep,
          },
          name: socio,
          birth_date: formatTimeYYYYMMDD(dataNiver),
          mother_name: inputs.motherPay,
          tax_id: inputs.owner_cpfPay,
          phones: [
            {
              country: 55,
              area: inputs.contactDDDPay,
              number: inputs.contactPay,
              type: tipoTelPay,
            },
          ],
        },
        tos_acceptance: {
          user_ip: "",
          date: new Date(),
        },
        business_category: "CLOTHING_AND_ACCESSORIES",
        email: inputs.emailPay,
        company: {
          name: razao_social,
          tax_id: cnpj_cpf,
          address: {
            street: end_empresa,
            number: end_num_empresa,
            complement: end_comp_empresa,
            locality: end_bairro,
            city: end_cidade,
            region_code: end_estado,
            country: "BRA",
            postal_code: end_cep,
          },
          phones: [
            {
              country: 55,
              area: end_ddd,
              number: end_tel,
              type: tipoTelEmp,
            },
          ],
        },
      },
    };
    try {
      if (await validaSlug(inputs.slug)) {
        const res = await publicRequest.post(`/company/`, obj);
        if (Object.keys(res.data).length > 0) {
          let company = res.data.companyId;
          const resCP = await publicRequest.get(`/company/find/${company}`);
          if (
            resCP.data.accountPay &&
            resCP.data.accountPay.data &&
            resCP.data.accountPay.data.error_messages
          ) {
            setCriado(true);
            Swal.fire("Atenção!", "Cliente criado porem com erro no PAGBANK");
          } else {
            setCriado(true);
            Swal.fire("Parabéns!", "A MoOda tem mais um cliente!", "success");
          }
        }
      } else {
        setCriado(false);
        Swal.fire("Atenção!", "URL existente");
      }
    } catch {
      console.log();
    }
  };
  const getStates = async () => {
    const res = await publicRequest.get(`1/customers/state`);

    setStates(res.data);
  };
  const handleChangeStart = (e) => {
    setDateNiver(e.target.value);
  };
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <div className="div_custom_Form">
        <span className="div_custom_UpdateTitle">Novo Cliente MoOda</span>
        <div className="div_custom_UpdateLeft">
          <div className="div_custom_UpdateItem">
            <label>CNPJ</label>
            <input
              type="text"
              name="cnpj"
              className="div_custom_UpdateInput"
              onChange={handleChangeCNPJ}
              maxLength="200"
              placeholder="12345678901234"
            />
          </div>
          <div className="div_custom_UpdateItem">
            <label>Nome cliente</label>
            <input
              type="text"
              name="name"
              className="div_custom_UpdateInput"
              onChange={handleChange}
              maxLength="200"
              defaultValue={razao_social ? razao_social : ""}
            />
          </div>
          <div className="div_custom_UpdateItem">
            <label>Url</label>
            <input
              type="text"
              name="slug"
              className="div_custom_UpdateInput"
              onChange={handleChange}
              maxLength="20"
              placeholder="nomeUrl"
            />
          </div>
          <div className="div_custom_UpdateItem">
            <label>Plano</label>
            <select
              className="div_custom_Select"
              name="position"
              id="position"
              onChange={handleChangePlano}
              value={plano}
            >
              <option value=""></option>
              <option value="Bronze">Bronze</option>
              <option value="Prata">Prata</option>
              <option value="Ouro">Ouro</option>
              <option value="Diamante">Diamante</option>
            </select>
          </div>

          <div className="div_custom_UpdateItem">
            <label>Exibe Dashboard?</label>
            <select
              className="div_custom_Select"
              name="dash"
              id="dash"
              onChange={handleChangeDash}
              value={showDashboard}
            >
              <option value=""></option>
              <option value="true">Sim</option>
              <option value="false">Não</option>
            </select>
          </div>
          <div className="div_custom_UpdateItem">
            <label>Exibe Frete?</label>
            <select
              className="div_custom_Select"
              name="dash"
              id="dash"
              onChange={handleChangeFrete}
              value={showFrete}
            >
              <option value=""></option>
              <option value="true">Sim</option>
              <option value="false">Não</option>
            </select>
          </div>
          <span className="subTitle">Dados do Proprietário</span>
          <div className="div_custom_UpdateItem">
            <label>Nome do Proprietário</label>
            <input
              type="text"
              name="namePay"
              defaultValue={socio ? socio : ""}
              className="div_custom_UpdateInput"
              onChange={handleChange}
              maxLength="200"
            />
          </div>
          <div className="div_custom_UpdateItem">
            <label>Email acesso ao PagBank</label>
            <input
              type="email"
              name="emailPay"
              className="div_custom_UpdateInput"
              onChange={handleChange}
              maxLength="200"
              placeholder="email@email.com"
            />
          </div>
          <div className="div_custom_UpdateItem">
            <label>Data Nascimento do Proprietário</label>
            <input
              type="date"
              id="startDate"
              name="trip-start"
              min="2023-01-01"
              onChange={handleChangeStart}
            />
          </div>
          <div className="div_custom_UpdateItem">
            <label>Nome da mãe Proprietário</label>
            <input
              type="text"
              name="motherPay"
              className="div_custom_UpdateInput"
              onChange={handleChange}
              maxLength="200"
            />
          </div>
          <div className="div_custom_UpdateItem">
            <label>CPF do Proprietário do CNPJ</label>
            <input
              type="text"
              pattern="\d*"
              name="owner_cpfPay"
              className="div_custom_UpdateInput"
              onChange={handleChange}
              maxLength="11"
              placeholder="12345678999"
            />
          </div>
          <div className="div_custom_UpdateItem">
            <label>CEP</label>
            <input
              type="text"
              pattern="\d*"
              name="cepPay"
              onChange={handleChangeCEP}
              className="div_custom_UpdateInput"
              maxLength="8"
              placeholder="03125808"
            />
          </div>
          <div className="div_custom_UpdateItem">
            <label>Endereço</label>
            <input
              type="text"
              name="street"
              onChange={handleChange}
              className="div_custom_UpdateInput"
              defaultValue={street ? street : ""}
              maxLength="200"
            />
          </div>
          <div className="div_custom_UpdateItem">
            <label>Numero</label>
            <input
              type="text"
              name="numberPay"
              onChange={handleChange}
              className="div_custom_UpdateInput"
              maxLength="8"
            />
          </div>
          <div className="div_custom_UpdateItem">
            <label>Complemento</label>
            <input
              type="text"
              name="complementPay"
              onChange={handleChange}
              className="div_custom_UpdateInput"
              maxLength="8"
            />
          </div>
          <div className="div_custom_UpdateItem">
            <label>Estado</label>
            <select
              className="div_custom_Select"
              name="state"
              value={state ? state : ""}
            >
              <option value=""></option>
              {states
                ? states.map((item) => (
                    <option value={item.stateId}>{item.state}</option>
                  ))
                : false}
            </select>
          </div>
          <div className="div_custom_UpdateItem">
            <label>DDD</label>
            <input
              type="text"
              name="contactDDDPay"
              className="div_custom_UpdateInput"
              maxLength="2"
              placeholder="11"
              onChange={handleChange}
            />
          </div>
          <div className="div_custom_UpdateItem">
            <label>Telefone</label>
            <input
              type="text"
              name="contactPay"
              className="div_custom_UpdateInput"
              maxLength="11"
              placeholder="123456789"
              onChange={handleChange}
            />
          </div>
          <div className="div_custom_UpdateItem">
            <label>Tipo Telefone</label>
            <select
              className="div_custom_Select"
              name="typeContact"
              id="typeContact"
              onChange={handleChangeTelPay}
              value={tipoTelPay}
            >
              <option value=""></option>
              <option value="MOBILE">Celular</option>
              <option value="BUSINESS">Empresa</option>
              <option value="HOME">Casa</option>
            </select>
          </div>
          <span className="subTitle">Dados do Empresa</span>
          <div className="div_custom_UpdateItem">
            <label>CEP</label>
            <input
              type="text"
              pattern="\d*"
              name="end_cep"
              className="div_custom_UpdateInput"
              maxLength="8"
              defaultValue={end_cep ? end_cep : ""}
              placeholder="03125808"
            />
          </div>
          <div className="div_custom_UpdateItem">
            <label>Endereço</label>
            <input
              type="text"
              name="end_empresa"
              className="div_custom_UpdateInput"
              defaultValue={end_empresa ? end_empresa : ""}
              maxLength="200"
            />
          </div>
          <div className="div_custom_UpdateItem">
            <label>Numero</label>
            <input
              type="text"
              name="end_num_empresa"
              onChange={handleChange}
              className="div_custom_UpdateInput"
              maxLength="8"
              defaultValue={end_num_empresa ? end_num_empresa : ""}
            />
          </div>
          <div className="div_custom_UpdateItem">
            <label>Complemento</label>
            <input
              type="text"
              name="complementPay"
              onChange={handleChange}
              className="div_custom_UpdateInput"
              maxLength="8"
              defaultValue={end_comp_empresa ? end_comp_empresa : ""}
            />
          </div>
          <div className="div_custom_UpdateItem">
            <label>Estado</label>
            <select
              className="div_custom_Select"
              name="state"
              value={end_estado ? end_estado : ""}
            >
              <option value=""></option>
              {states
                ? states.map((item) => (
                    <option value={item.stateId}>{item.state}</option>
                  ))
                : false}
            </select>
          </div>
          <div className="div_custom_UpdateItem">
            <label>DDD</label>
            <input
              type="text"
              name="end_ddd"
              className="div_custom_UpdateInput"
              maxLength="2"
              defaultValue={end_ddd ? end_tel : ""}
              placeholder="11"
            />
          </div>
          <div className="div_custom_UpdateItem">
            <label>Telefone</label>
            <input
              type="text"
              name="end_tel"
              className="div_custom_UpdateInput"
              maxLength="12"
              defaultValue={end_tel ? end_tel : ""}
              placeholder="12345678"
            />
          </div>
          <div className="div_custom_UpdateItem">
            <label>Tipo Telefone</label>
            <select
              className="div_custom_Select"
              name="typeContact"
              id="typeContact"
              onChange={handleChangeTelEmp}
              value={tipoTelEmp}
            >
              <option value=""></option>
              <option value="MOBILE">Celular</option>
              <option value="BUSINESS">Empresa</option>
              <option value="HOME">Casa</option>
            </select>
          </div>
        </div>
        <div className="div_custom_UpdateRight">
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<SaveIcon />}
            onClick={click}
          >
            Cadastrar
          </Button>
        </div>
        {criado ? (
          <div className="div_custom_UpdateItem">
            <label>Admin:{urlAdminMooda + inputs.slug + "/login"}</label>
            <label>Mobile:{urlMooda + inputs.slug + "/home"}</label>
            <label>Vendedor:{urlMooda + inputs.slug + "/employee"}</label>
          </div>
        ) : (
          false
        )}
      </div>
    </Container>
  );
};

export default Company;
